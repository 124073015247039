/* eslint-disable react/prop-types */

import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import AssociatesDetailPage from '../components/AssociatesDetailPage';
import SEO from '../components/seo';

const AssociatePage = ({ data, pageContext }) => {
  const { metadata, localizations } = data.strapiAssociates;
  const global = data.strapiGlobal;
  return (
    <>
      <SEO seo={metadata} global={global} />
      <Layout global={global} pageContext={{ ...pageContext, localizations }}>
        <AssociatesDetailPage data={data.strapiAssociates} />
      </Layout>
    </>
  );
};

export default AssociatePage;

export const query = graphql`
  fragment globalAssociateData on StrapiGlobal {
    footer {
      ETFDepartments {
        title
        id
      }
      ETFDepartmentLinks {
        url
        title
        id
      }
      externLinks {
        id
        title
        url
      }
      address {
        title
        Description
        description
      }
      contact {
        Fax
        Email
        Tel
        title
      }
      copyright {
        title
      }
      Social {
        id
        Link
      }
    }

    id
    metadata {
      metaDescription
      metaTitle
    }
    navbar {
      mainLinks {
        id
        url
        title
      }
      secondLinks {
        id
        title
        url
      }
      ETFDepartment {
        url
        title
      }
    }
  }
  query associatePageQuery($id: String!, $locale: String!) {
    strapiGlobal(locale: { eq: $locale }) {
      ...GlobalData
    }
    strapiAssociates(id: { eq: $id }) {
      biography
      biographyTitle
      contactInformation
      curriculumVitae
      curriculumVitaeSite
      associatedDepartment
      affiliation
      affiliationContent
      departmentContent
      fax
      faxNumber
      personalWeb
      personalWebSite
      phone
      phoneNumber
      position
      positionContent
      department {
        id
        locale
        title
      }
      id
      locale
      localizations {
        id
        locale
      }
      image {
        id
        url
      }
      metaData {
        id
        metaDescription
        metaTitle
      }
      name
      professorsTitle
      readMore
      reference
      referenceTitle
      slug
      surname
    }
  }
`;
