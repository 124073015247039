import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';

import './associatesDetails.scss';

const AssociatesDetailPage = (props) => (
  <div>
    <div className=" main-wrapper mx-auto col-12 col-lg-8">
      <div className=" title-container d-lg-flex mt-4">
        <div className="name-wrapper mb-3 col col-lg-8 d-md-flex">
          <div className="name-container">
            <p className="professor-title mb-2 mb-lg-0">{props.data.professorsTitle}</p>
            <p className="name">{`${props.data.name} ${props.data.surname}`}</p>
          </div>
          <div className="img-container justify-content-end d-flex col col-md-4 ms-md-auto">
            <img className="associate-image" src={props.data.image.url} alt="" />
          </div>
        </div>
      </div>
      <div className="about-professor">
        <hr className="mt-4" />
        <div className="info-wrapper">
          <div>
            <h4 className="contact mt-4">{props.data.contactInformation}</h4>
          </div>
          <div className="info-wrapper mt-2 mt-lg-5 d-lg-flex">
            <div className="col left-content col-lg-6">
              <div className="d-flex">
                <p className="left-title">{`${props.data.position}:`}</p>
                <p className="left-title-content">{props.data.positionContent}</p>
              </div>
              <div className="d-flex">
                <p className="left-title">{`${props.data.associatedDepartment}:`}</p>
                <p className="left-title-content">{props.data.departmentContent}</p>
              </div>
              <div className="d-flex">
                <p className="left-title">{`${props.data.affiliation}:`}</p>
                <p className="left-title-content">{props.data.affiliationContent}</p>
              </div>
            </div>
            <div className="col right-content col-lg-6">
              <div className="d-flex">
                <p className="title">{`${props.data.phone}:`}</p>
                <a
                  className="title-content"
                  href={`tel:${props.data.phoneNumber}`}
                >
                  {props.data.phoneNumber}
                </a>
              </div>
              <div className="d-flex">
                <p className="title">{`${props.data.fax}:`}</p>
                <a
                  className="title-content"
                  href={`tel:${props.data.faxNumber}`}
                >
                  {props.data.faxNumber}
                </a>
              </div>
              <div className="d-flex">
                <p className="title">{`${props.data.curriculumVitae}:`}</p>
                <a
                  className="title-content"
                  href={`mailto:${props.data.curriculumVitaeSite}`}
                >
                  {props.data.curriculumVitaeSite}
                </a>
              </div>
              <div className="d-flex">
                <p className="title">{`${props.data.personalWeb}:`}</p>
                <a
                  className="title-content"
                  href={`mailto:${props.data.personalWebSite}`}
                >
                  {props.data.personalWebSite}
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-4" />
        <h5 className="mt-3">{props.data.biographyTitle}</h5>
        <ReactMarkdown className="bio-content">
          {props.data.biography}
        </ReactMarkdown>
      </div>
    </div>
  </div>
);
AssociatesDetailPage.propTypes = {
  data: PropTypes.shape({
    professorsTitle: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    contactInfo: PropTypes.string.isRequired,
    biographyTitle: PropTypes.string.isRequired,
    biography: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    mail: PropTypes.string.isRequired,
    affiliation: PropTypes.string.isRequired,
    affiliationContent: PropTypes.string.isRequired,
    associatedDepartment: PropTypes.string.isRequired,
    contactInformation: PropTypes.string.isRequired,
    curriculumVitae: PropTypes.string.isRequired,
    curriculumVitaeSite: PropTypes.string.isRequired,
    departmentContent: PropTypes.string.isRequired,
    fax: PropTypes.string.isRequired,
    faxNumber: PropTypes.string.isRequired,
    personalWeb: PropTypes.string.isRequired,
    personalWebSite: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    positionContent: PropTypes.string.isRequired,

    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default AssociatesDetailPage;
